import {HttpParams} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {AuthService as Auth0Service, User} from '@auth0/auth0-angular';
import {fromEvent, Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {StorageManagerService} from '../../../modules/storage/storage-manager.service';
import {AuthInitiationService} from './auth-initiation-sercive';

export interface SignupSurvey {
    sql_usage?: string;
}

export interface ExtraClaim {
    signup_survey?: SignupSurvey;
}

export interface Membership {
    name: string;
    identifier: string;
    account_type: 'MANAGED' | 'SELF_BUILD' | 'DEMO';
    created_at: string;
    tags?: string[];
}

export interface UserDataInterface {
    email: string;
    name: string;
    givenName: string;
    familyName: string;
}

export type AuthUser = User;

@Injectable()
export class AuthService implements OnDestroy {
    public logout$ = new Subject<void>();
    private authUser$: Observable<AuthUser> = this.auth0Service.user$;
    private broadcastChannel: BroadcastChannel;

    public user$: Observable<UserDataInterface> = this.authUser$.pipe(
        map((user) =>
            user
                ? {
                      email: user.email,
                      name: user.name,
                      givenName: user.given_name,
                      familyName: user.family_name,
                  }
                : undefined,
        ),
    );

    public intercomUserHash$: Observable<string | undefined> = this.authUser$.pipe(
        map((u) => u?.['https://platform.pecan.ai/intercom_user_hash']),
    );

    public userExtras$: Observable<ExtraClaim | undefined> = this.authUser$.pipe(
        map((u) => u?.['https://platform.pecan.ai/extra']),
    );

    private userMemberships$: Observable<Membership[] | undefined> = this.authUser$.pipe(
        map((u) => u?.['https://platform.pecan.ai/member_of']),
    );

    public defaultTenant$: Observable<string | undefined> = this.userMemberships$.pipe(
        map((memberships) => memberships?.[0]?.identifier),
    );

    public defaultTenantDisplayName$: Observable<string | undefined> = this.userMemberships$.pipe(
        map((memberships) => memberships?.[0]?.name),
    );

    private destroy$ = new Subject<void>();

    public getPermissionsForTenant(tenant: string): Observable<Set<string>> {
        return this.auth0Service.idTokenClaims$.pipe(
            map((token) => {
                if (!token) {
                    return new Set([]);
                }

                const globalPermissions: string[] =
                    token['https://platform.pecan.ai/auth0_global_permissions']?.permissions;
                const permissionsByTenant: Record<string, {permissions: string[]}> =
                    token['https://platform.pecan.ai/auth0_orgs_permissions'];
                return new Set([...globalPermissions, ...(permissionsByTenant[tenant]?.permissions || [])]);
            }),
        );
    }

    constructor(
        // This dependency makes sure the config is set before the service is used
        _authInitiationService: AuthInitiationService,
        private auth0Service: Auth0Service,
        private storageManagerService: StorageManagerService,
    ) {
        fromEvent(window, 'login_required')
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.logout();
            });

        this.registerBroadcastChannel();
    }

    private registerBroadcastChannel() {
        this.broadcastChannel = new BroadcastChannel('pecan::platform');
        this.broadcastChannel.onmessage = (message) => {
            if (message.data === 'logout') this.logout();
            this.broadcastChannel.close();
        };
    }

    logout(returnUrl?: string): void {
        this.storageManagerService.loginRedirectUrl.subject$.next(returnUrl);
        this.logout$.next();
        this.broadcastChannel.postMessage('logout');
        this.auth0Service.logout({logoutParams: {returnTo: `${window.location.origin}/logout`}});
    }

    isAuthenticated$(): Observable<boolean> {
        return this.auth0Service.isAuthenticated$;
    }

    private initAuth0() {
        return new Promise((resolve) => {
            this.user$.subscribe((user) => {
                if (user !== undefined && user !== null) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    public appendAuthParam(params: HttpParams): Observable<HttpParams> {
        return this.auth0Service
            .getAccessTokenSilently()
            .pipe(map((token) => params.append('Authorization', `Bearer ${token}`)));
    }

    public init() {
        return this.initAuth0();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
