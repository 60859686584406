import {DOCUMENT, Location} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {PlatformAnalyticsService} from '../modules/analytics/local-services/platform-analytics.service';
import {DocumentTitleService} from './core/services/document-title.service';
import UAParser from 'ua-parser-js';

const NO_HEADER_MODE_URL_PATTERNS = [
    '^/blueprints/edit',
    '^/blueprints/templates/',
    '^/reset-password',
    '^/flow/\\d+',
    '^/notebooks\\?nutbookPath=notebook-flow',
    '^/notebooks/modal\\?nutbookPath=notebook-flow',
    '^/new-flow\\?flowInnerPath=(?!%2Flist)(?!$).*$',
];
const NO_PADDING_MODE_URL_PATTERNS = [
    '^/home',
    '^/data',
    '^/blueprints',
    '^/blueprints/edit',
    '/models',
    '/learn',
    '/org-settings',
    '^/flow',
    '^$',
    '^/notebooks',
    '^/dashboards',
    '^/new-flow',
];

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    public noPadding: boolean;
    public noHeader: boolean;
    public urlString$ = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd || event instanceof NavigationEnd),
        map((event: NavigationStart | NavigationEnd) => event.url),
    );
    public isLogin$ = this.urlString$.pipe(map((url) => url.includes('login')));
    public isHomePage$ = this.urlString$.pipe(map((url) => url === '/'));
    public fullPage$ = combineLatest([this.isLogin$, this.isHomePage$]).pipe(map(([login, home]) => login || home));

    constructor(
        translate: TranslateService,
        private location: Location,
        private router: Router,
        private documentTitleLogicService: DocumentTitleService,
        private appTitleService: Title,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        private platformAnalyticsService: PlatformAnalyticsService,
    ) {
        translate.setDefaultLang('en');
        this.platformAnalyticsService.track('platform:app-loaded');
    }

    private setHeaderVisibility() {
        this.noHeader = !!NO_HEADER_MODE_URL_PATTERNS.find((url) => this.location.path().match(url));
        this.noPadding = !!NO_PADDING_MODE_URL_PATTERNS.find((url) => this.location.path().match(url));
    }

    ngOnInit() {
        this.documentTitleLogicService.title$.subscribe((titleToUpdate) =>
            this.appTitleService.setTitle(titleToUpdate),
        );
        this.setHeaderVisibility();
        this.location.onUrlChange(() => this.setHeaderVisibility());
    }

    shouldDisplaySmallScreenSizeMessage(): boolean {
        const parser = new UAParser(window.navigator.userAgent);
        const deviceType = parser.getDevice().type;
        return deviceType === 'mobile' || deviceType === 'wearable' || deviceType === 'tablet';
    }
}
