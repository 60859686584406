<div class="login-container">
    <div class="login-header">
        <i class="pecan-icon"></i>
        <p class="welcome" nz-typography>{{ 'LOGIN_PAGE.WELCOME' | translate }}</p>
        <span nz-typography>{{ 'LOGIN_PAGE.WELCOME_DESCRIPTION' | translate }}</span>
    </div>
    <div class="login-content">
        <form nz-form [formGroup]="form" class="login-form" (ngSubmit)="getOrgByEmail()">
            <nz-form-item>
                <nz-form-control [nzErrorTip]="'LOGIN_PAGE.EMAIL_REQUIRED' | translate">
                    <input
                        id="login-email-input"
                        type="email"
                        nz-input
                        nzSize="large"
                        formControlName="email"
                        [placeholder]="'LOGIN_PAGE.EMAIL' | translate"
                    />
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="organizations.length">
                <nz-form-control>
                    <nz-select
                        [nzPlaceHolder]="'LOGIN_PAGE.SELECT_ORG_PLACEHOLDER' | translate"
                        formControlName="organization"
                        [ngModel]="organizations[0]?.id"
                    >
                        <nz-option
                            *ngFor="let org of organizations"
                            [nzLabel]="org.display_name"
                            [nzValue]="org.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <button nz-button nzType="primary" nzBlock nzSize="large" type="submit" [nzLoading]="loading">
                {{ 'LOGIN_PAGE.CONTINUE_BUTTON' | translate }}
            </button>
        </form>
    </div>
</div>
