import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AuthService} from '../../app/core/authentication/auth.service';
import {CustomerService} from '../customer/customer.service';

@Injectable({providedIn: 'root'})
export class GtmReporterService implements OnDestroy {
    private destroy$ = new Subject<void>();

    constructor(customerService: CustomerService, authService: AuthService) {
        this.syncDataLayer('sessionInfo.tenant', customerService.selectedTenant$);
        this.syncDataLayer('sessionInfo.orgDetails', customerService.selectedTenantDetails$);
        this.syncDataLayer('sessionInfo.userExtras', authService.userExtras$);
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private syncDataLayer(key: string, stream$: Observable<any>): void {
        stream$.pipe(takeUntil(this.destroy$)).subscribe((v) => this.addToDataLayer({[key]: v}));
    }

    private addToDataLayer(obj: object): void {
        const topWindow: any = window.top;
        topWindow.dataLayer = topWindow.dataLayer ?? [];
        topWindow.dataLayer.push(obj);
    }
}
