import {NgModule} from '@angular/core';
import {BannerViewComponent} from './banner-view/banner-view.component';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {CommonModule} from '@angular/common';
import {FeatureFlagsModule} from '../../../platform-common/feature-flags/feature-flags.module';

@NgModule({
    imports: [NzAlertModule, CommonModule, FeatureFlagsModule],
    declarations: [BannerViewComponent],
    exports: [BannerViewComponent],
})
export class BannerModule {}
