import {Injectable, TemplateRef} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ErrorMessageTemplateService {
    private templateRef: TemplateRef<HTMLElement>;

    get template(): TemplateRef<HTMLElement> {
        return this.templateRef;
    }
    set template(template: TemplateRef<HTMLElement>) {
        this.templateRef = template;
    }
}
