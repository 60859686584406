import {NgModule} from '@angular/core';
import {EffectivePermissionPipe} from './effective-permission.pipe';
import {EffectivePermissionsService} from './effective-permissions.service';

@NgModule({
    providers: [EffectivePermissionsService],
    declarations: [EffectivePermissionPipe],
    exports: [EffectivePermissionPipe],
})
export class EffectivePermissionsModule {}
