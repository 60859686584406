import {Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {ErrorMessageTemplateService} from '../../services/global-template.service';

@Component({
    selector: 'app-error-message-template',
    templateUrl: './app-error-message-template.component.html',
    styleUrls: ['./app-error-message-template.component.scss'],
})
export class AppErrorMessageTemplateComponent implements OnInit {
    @ViewChild('errorNotificationTemplate', {static: true}) errorNotificationTemplate!: TemplateRef<HTMLElement>;
    public isModalVisible = false;
    constructor(private templateService: ErrorMessageTemplateService) {}

    public ngOnInit(): void {
        this.templateService.template = this.errorNotificationTemplate;
    }
}
