<ng-container *ngIf="shouldDisplaySmallScreenSizeMessage(); else notSmallScreen">
    <app-small-screen-message></app-small-screen-message>
</ng-container>

<ng-template #notSmallScreen>
    <div
        style="height: 100%"
        *ngIf="{
            isLogin: isLogin$ | async,
            fullPage: fullPage$ | async
        } as data"
        [ngClass]="{isLogin: data.isLogin, 'full-page': data.fullPage}"
    >
        <app-error-message-template></app-error-message-template>
        <section class="page" [ngClass]="{'no-padding': noPadding, 'no-header': noHeader}">
            <app-banner-view></app-banner-view>
            <app-top-navigation-bar *ngIf="!noHeader" [appAutomationId]="'top-navigation-bar'"></app-top-navigation-bar>
            <div class="content-container">
                <router-outlet></router-outlet>
            </div>
        </section>
    </div>
</ng-template>
