<ng-template #errorNotificationTemplate let-errorEvent="data">
    <div class="custom-notification">
        <div class="notice">
            <span nz-icon nzType="close-circle" nzTheme="fill" class="icon"></span>
            <div class="message">{{ errorEvent?.key }}</div>
        </div>
        <a class="linkable" (click)="isModalVisible = true">
            {{ 'READ_MORE' | translate }}
        </a>
    </div>
    <nz-modal
        [(nzVisible)]="isModalVisible"
        nzTitle="Server Error"
        (nzOnCancel)="isModalVisible = false"
        [nzCancelText]="null"
        (nzOnOk)="isModalVisible = false"
    >
        <ng-container *nzModalContent>
            <p>
                <b>{{ errorEvent?.key }}</b>
            </p>
            <p>{{ errorEvent?.message }}</p>
        </ng-container>
    </nz-modal>
</ng-template>
