import {Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
    selector: '[appAutomationId]',
})
export class AutomationIdDirective {
    constructor(private elementRef: ElementRef, private renderer2: Renderer2) {}

    @Input()
    set appAutomationId(id: string) {
        this.renderer2.setAttribute(this.elementRef.nativeElement, 'data-automation-id', id);
    }
}
