<a class="menu-item" routerLink="new-flow" routerLinkActive="active">
    {{ 'BREADCRUMBS.FLOW' | translate }}
</a>

<a class="menu-item" routerLink="data" routerLinkActive="active">
    {{ 'BREADCRUMBS.DATA' | uppercase | translate }}
</a>

<a
    *ngIf="'show-external-dashboards-tab' | ffObserve | async"
    class="menu-item"
    routerLink="dashboards"
    routerLinkActive="active"
>
    {{ 'BREADCRUMBS.CUSTOM_DASHBOARDS' | translate }}
</a>
