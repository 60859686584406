import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {getHostContextConsumer, getHostContextProvider} from '@pecan/host-context-sdk';
import {AppModule} from './app/app.module';
import {getAuth0Provider} from './app/core/authentication/auth0-config.service';
import {environment} from './environments/environment';
import {HostContextConsumerService, HostContextProviderService} from './host-context';

async function start() {
    const hostContextProvider = await getHostContextProvider();
    const hostContextConsumer = await getHostContextConsumer({consumerId: 'platform'});
    const config = hostContextConsumer.config.getConfig();

    const auth0ConfigProvider = await getAuth0Provider(config);
    if (environment.production) {
        enableProdMode();
    }

    function onStartup() {
        // @ts-ignore
        window.appLoadingAnimation.destroy();
    }

    return platformBrowserDynamic([
        {
            provide: HostContextProviderService,
            useValue: hostContextProvider,
        },
        {
            provide: HostContextConsumerService,
            useValue: hostContextConsumer,
        },
        auth0ConfigProvider,
    ])
        .bootstrapModule(AppModule)
        .then(onStartup);
}

// eslint-disable-next-line no-console
start().catch((err: Error) => console.log(err));

if (location.host === 'localhost:4300') {
    // eslint-disable-next-line no-console
    console.error(
        '🚫🚫 localhost:4300 is not behind the local-ingress 🚫🚫 \n',
        '- Use http://localhost:3030 \n',
        '- Make sure local-ingress is up (README.md) \n',
    );
}
