import {Injectable} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {AuthService, UserDataInterface} from '../authentication/auth.service';
import {map} from 'rxjs/operators';
import {CustomerService} from '../../../modules/customer/customer.service';

// https://stackoverflow.com/questions/70956050/how-do-i-declare-object-value-type-without-declaring-key-type
const satisfies =
    <T>() =>
    <U extends T>(u: U) =>
        u;

export type PermissionKeys = keyof PermissionsService['permissions'];

@Injectable()
export class PermissionsService {
    private permissions = satisfies<Record<string, (() => Observable<boolean>)[]>>()({
        'models:actions:open-airflow': [this.isPecanEmployee()],
        'models:actions:set-hidden': [this.isPecanEmployee()],
        'external-dashboards:actions:add': [this.isPecanEmployee()],
        'external-dashboards:actions:edit': [this.isPecanEmployee()],
        'predictions:actions:skip-validations': [this.isPecanEmployee()],
        'customer-selection:show-account-type-in-dropdown': [this.isPecanEmployee()],
    });

    private user$: Observable<UserDataInterface> = this.authService.user$;

    public constructor(private authService: AuthService, private customerService: CustomerService) {}

    public getIsPermitted(key: PermissionKeys): Observable<boolean> {
        const validatorObservables: Observable<boolean>[] = this.permissions[key].map((action) => action());
        return combineLatest(validatorObservables).pipe(map((results) => results.every((res) => !!res)));
    }

    private isPecanEmployee(): () => Observable<boolean> {
        return () => this.user$.pipe(map((user) => user.email.includes('@pecan.ai')));
    }
}
