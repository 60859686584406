import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {CustomerService} from 'src/modules/customer/customer.service';
import {AuthService} from '../authentication/auth.service';

@Injectable()
export class EffectivePermissionsService {
    private permissions$: Observable<Set<string>> = this.customerService.selectedTenant$.pipe(
        switchMap((t) => this.authService.getPermissionsForTenant(t)),
    );

    constructor(private authService: AuthService, private customerService: CustomerService) {}

    public getIsPermitted(key: string): Observable<boolean> {
        return this.permissions$.pipe(map((permissions) => permissions.has(key)));
    }
}
