import {BehaviorSubject, EMPTY, Observable} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {AnalyticsTrackEmitter} from './analytics-emitter';

export abstract class AnalyticsEmitterWithContext<ContextualPayload extends Object> implements AnalyticsTrackEmitter {
    private contextualPayloadStream$ = new BehaviorSubject<Observable<ContextualPayload>>(EMPTY);
    private contextualPayload$ = this.contextualPayloadStream$.pipe(switchMap((cp$) => cp$));

    constructor(private onTrack: (key: string, value: object) => void) {}

    public track(key: string, payload?: object): void {
        this.contextualPayload$.pipe(take(1)).subscribe((contextualPayload) => {
            this.onTrack(key, {...contextualPayload, ...payload});
        });
    }

    public setContextualPayloadStream(contextualPayloadStream: Observable<ContextualPayload>): void {
        this.contextualPayloadStream$.next(contextualPayloadStream);
    }
}
