import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'initials',
})
export class InitialsPipe implements PipeTransform {
    transform(value: string): any {
        if (!value) {
            return '';
        }
        const words = value.split(' ');
        const initials = words.map((w) => w.slice(0, 1).toUpperCase());
        return initials.join('');
    }
}
