import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconsModule} from './icons/icons.module';
import {PipesModule} from '../pipes/pipes.module';
import {InfoBarComponent} from './info-bar/info-bar.component';
import {LoadingBlockComponent} from './loading-block/loading-block.component';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {DirectivesModule} from '../directives/directives.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
    imports: [
        CommonModule,
        IconsModule,
        PipesModule,
        FormsModule,
        TranslateModule,
        DirectivesModule,
        MatProgressSpinnerModule,
        MatMenuModule,
    ],
    declarations: [InfoBarComponent, LoadingBlockComponent],
    exports: [IconsModule, InfoBarComponent, LoadingBlockComponent],
})
export class GenericComponentsModule {}
