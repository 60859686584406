import {Injectable} from '@angular/core';
import {WalkMeFlow} from './walk-me-flow';

interface WalkMeApi {
    startFlowById: (flowId: number) => boolean;
    startContentById: (flowId: number) => void;
}

@Injectable()
export class WalkMeApiService {
    public startFlow(flow: WalkMeFlow): boolean {
        return !!this.getWalkMeApi()?.startFlowById(flow);
    }

    public startContentById(flow: WalkMeFlow): void {
        return this.getWalkMeApi()?.startContentById(flow);
    }

    private getWalkMeApi(): WalkMeApi {
        const res = (window as any).WalkMeAPI;
        if (!res) {
            // eslint-disable-next-line no-console
            console.error('Trying to use WalkMeApi but it is not available');
        }
        return res;
    }
}
