import {Injectable} from '@angular/core';
import {AuthClientConfig} from '@auth0/auth0-angular';
import {Auth0ConfigService} from './auth0-config.service';

@Injectable({providedIn: 'root'})
export class AuthInitiationService {
    constructor(authClientConfig: AuthClientConfig, auth0ConfigService: Auth0ConfigService) {
        authClientConfig.set({
            domain: auth0ConfigService.auth0Domain,
            clientId: auth0ConfigService.orgAwareClientId,
            httpInterceptor: {
                allowedList: ['api/*', '/api/*', '_bp/api/*', '/magpie/api/*', '/_org/api/*', '_*', '/_*'],
            },
            cacheLocation: 'localstorage',
            authorizationParams: {
                audience: auth0ConfigService.audience,
                redirect_uri: window.location.origin,
            },
            useRefreshTokens: auth0ConfigService.useRefreshTokens,
            useRefreshTokensFallback: auth0ConfigService.useRefreshTokensFallback,
        });
    }
}
