import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconComponent} from './icon/icon.component';
import {FormsModule} from '@angular/forms';
import {SvgIconsService} from './services/svg-icon.service';

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [IconComponent],
    exports: [IconComponent],
    providers: [{provide: APP_INITIALIZER, useFactory: initializeSvgFactory, deps: [SvgIconsService], multi: true}],
})
export class IconsModule {}

export function initializeSvgFactory(svgIconsService: SvgIconsService) {
    return () =>
        svgIconsService.initSvgIcons().catch(() => {
            throw new Error('Error in loading SVG icons');
        });
}
