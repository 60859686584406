export const LEARN = {
    TITLE: 'Learn',
    SUB_TITLE: 'Videos designed to take you from zero to Predictive Analytics hero.',
    CORE_CONCEPTS: 'Core Concepts',
    TUTORIALS: 'Tutorials',
    COMING_SOON: 'Coming Soon',
    INSPIRATION: 'Inspiration',
    INTERACTIVE: 'Interactive tutorial',
    MORE_RESOURCE: 'More resources',
    CONNECT_HEADING: 'Connect your data',
    CONNECT_DESCRIPTION: 'Start quickly by uploading your test or demo files, or connect to a hosted service',
    BUILD_HEADING: 'Build a predictive flow',
    BUILD_DESCRIPTION: 'Select a template and create tables that Pecan will join and process into a dataset',
    EVALUATE_HEADING: 'Evaluate your model',
    EVALUATE_DESCRIPTION: 'See how well your predictive flow performs, compares to other benchmarks',
    PREDICTIVE_GEN_AI: 'Predictive GenAI',
    PREDICTIVE_GEN_AI_DESCRIPTION:
        'Frame your business needs as a predictive question, and generate an SQL notebook to help you solve it',
    USE_HEADING: 'Use your data in queries',
    USE_DESCRIPTION: 'When editing SQL queries, you can point to your data',
    ENTITY_HEADING: 'Entity in Pecan queries',
    ENTITY_DESCRIPTION: 'The Entity query defines the population the model will learn from',
    TARGET_HEADING: 'Target in Pecan queries',
    TARGET_DESCRIPTION: 'The Target query identifies the behavior that is to be predicted for each Entity',
    ATTRIBUTE_HEADING: 'Attribute in Pecan queries',
    ATTRIBUTE_DESCRIPTION: 'Additional data columns to enrich the model during the learning proccess',
    DATA_ANALYST_HEADING: 'Succeed as a Data Analyst',
    DATA_ANALYST_DESCRIPTION: 'Pecan’s low-code predictive analytics platform makes AI accessible to businesses',
    STEP_BY_STEP_HEADING: 'Step by step guide',
    STEP_BY_STEP_DESCRIPTION: 'Follow this guide to create a training set and train your first predictive model',
    RESOURCE_DOCS_HEADING: 'Articles',
    RESOURCE_BLOG_HEADING: 'Blog',
    RESOURCE_GLOSSARY_HEADING: 'Glossary',
    RESOURCE_PRICING_HEADING: 'Pricing',
};
