import {Injectable} from '@angular/core';
import {AuthService} from '../../app/core/authentication/auth.service';
import {CustomerService} from '../customer/customer.service';

@Injectable({providedIn: 'root'})
export class WindowSessionContext {
    private context: any = {};

    public constructor(private authService: AuthService, private customerService: CustomerService) {
        (window as any).sessionContext = this.context;
        this.customerService.selectedTenant$.subscribe((tenant) => this.setValue('tenant', tenant));
        this.customerService.selectedTenantDetails$.subscribe((details) => this.setValue('tenantDetails', details));
        this.authService.user$.subscribe((auth0User) => {
            this.setValue('given_name', auth0User?.givenName);
            this.setValue('family_name', auth0User?.familyName);
            this.setValue('name', auth0User?.name);
            this.setValue('email', auth0User?.email);
        });
    }

    private setValue(key: string, value: any): void {
        this.context[key] = value;
    }
}
