import {TranslateCompiler, TranslateLoader} from '@ngx-translate/core';
import {TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import {of} from 'rxjs';
import translation from '../assets/i18n/en';

/*
    ! IMPORTANT ! (by Diamant)
    LazyLoader is async, so it doesn't allow using translations from the
    controller right on app startup.

    SyncLoader is not async, and forces the application to wait for translation to load.
    This approach is a bad practice, but is legit for only one language.
    I set the language to be hard-coded 'en' to force us to read this comment as we add new language.

    class LazyLoader implements TranslateLoader {
        getTranslation(lang: string) {
            const translation = import(`../assets/i18n/${lang}`).then(module => module.default);
            return from(translation);
        }
    }
*/

class SyncLoader implements TranslateLoader {
    getTranslation(_lang: string) {
        return of(translation);
    }
}

export const appI18NConfig = {
    loader: {
        provide: TranslateLoader,
        useFactory: () => new SyncLoader(),
    },
    compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
    },
};
