import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzInputModule} from 'ng-zorro-antd/input';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ModelsManagementService} from './services/models-management.service';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {NzListModule} from 'ng-zorro-antd/list';
import {RouterLinkActive, RouterLinkWithHref, RouterOutlet} from '@angular/router';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {GenericComponentsModule} from '../../../platform-common/generic-components/generic-components.module';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {PipesModule} from '../../../platform-common/pipes/pipes.module';
import {DirectivesModule} from '../../../platform-common/directives/directives.module';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {FeatureFlagsModule} from '../../../platform-common/feature-flags/feature-flags.module';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzEmptyModule} from 'ng-zorro-antd/empty';
import {PermissionsModule} from '../../app/core/permissions/permissions.module';
import {LightModelsListPageComponent} from './light-models-list-page/light-models-list-page.component';
import {NzTableModule} from 'ng-zorro-antd/table';

@NgModule({
    declarations: [LightModelsListPageComponent],
    imports: [
        CommonModule,
        NzIconModule,
        NzButtonModule,
        NzInputModule,
        FormsModule,
        TranslateModule,
        ScrollingModule,
        NzListModule,
        RouterLinkWithHref,
        NzTypographyModule,
        GenericComponentsModule,
        NzToolTipModule,
        NzDropDownModule,
        RouterLinkActive,
        RouterOutlet,
        NzSkeletonModule,
        NzFormModule,
        NzAvatarModule,
        PipesModule,
        DirectivesModule,
        NzTagModule,
        FeatureFlagsModule,
        NzSelectModule,
        NzDividerModule,
        NzEmptyModule,
        PermissionsModule,
        NzTableModule,
    ],
    providers: [ModelsManagementService],
})
export class ModelsManagementModule {}
