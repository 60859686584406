import base from './base.json';
import {WEBSERVER_ERRORS} from './webserver-errors';
import {MODELS} from './models';
import {FORM_ERRORS} from './form-errors';
import {LEARN} from './learn';

export default {
    ...base,
    WEBSERVER_ERRORS,
    MODELS,
    FORM_ERRORS,
    LEARN,
};
