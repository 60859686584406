import {WebserverErrorKey} from '../../../common/modules/types/webserver-error-key';

const ERRORS: {[key in keyof typeof WebserverErrorKey]: string} = {
    GENERAL: 'Generic Webserver error',
    NOT_FOUND_ROUTE: 'Route not found',
    UNAUTHENTICATED_USER: 'Unauthenticated user',
    UNAUTHORIZED_ACCESS: 'Unauthorized access',
    WRONG_PARAMS: 'Wrong params',
};

export const WEBSERVER_ERRORS = {
    ERRORS,
    SHOW_ERROR: 'Show error',
    DEFAULT_ERROR_DISPLAY_TEXT: 'Unknown error',
    SNACKBAR_SUBTITLE: 'Oops! Something happened! Our best team is on the job.',
};
