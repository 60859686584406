import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {EffectivePermissionsService} from './effective-permissions.service';

@Pipe({
    name: 'effectivePermission',
})
export class EffectivePermissionPipe implements PipeTransform {
    constructor(private effectivePermissionsService: EffectivePermissionsService) {}
    public transform(key: string): Observable<boolean> {
        return this.effectivePermissionsService.getIsPermitted(key);
    }
}
