<div class="top-navigation-bar" *ngIf="!hideTopBar">
    <div class="left-container">
        <a
            class="pecan-icon"
            [routerLink]="''"
            nzTooltipPlacement="bottom"
            [nz-tooltip]="location.path().endsWith('/home') ? null : ('COMMON.LOGO_TOOLTIP' | translate)"
        ></a>
        <app-top-navigation-bar-links></app-top-navigation-bar-links>
    </div>
    <div class="right-container">
        <customer-selection></customer-selection>
        <button
            class="invite-button"
            [routerLink]="['/org-settings']"
            (click)="platformAnalyticsService.track('invitation button click')"
            nz-button
            nzSize="large"
            *ngIf="'platform:organization:member:read' | effectivePermission | async"
        >
            <span nz-icon nzType="mail" nzTheme="outline"></span>
            {{ 'COMMON.INVITE' | translate }}
        </button>
        <div class="user-section" *ngIf="user$ | async as user">
            <a
                class="user-name fs-exclude"
                nz-dropdown
                nzTrigger="click"
                [nzDropdownMenu]="userMenu"
                [appAutomationId]="'user-dropdown-trigger'"
                nz-tooltip=""
                [nzTooltipTitle]="user?.name"
            >
                <ng-container *ngIf="user?.name; else emailData">
                    <span class="text">
                        {{ user?.name }}
                    </span>
                </ng-container>
                <ng-template #emailData>
                    <span class="text">
                        {{ user?.email }}
                    </span>
                </ng-template>
                <span class="dropdown-arrow" nz-icon size="small" nzType="down"></span>
            </a>
            <span class="company">
                {{ userCompanyDisplayName$ | async }}
            </span>
            <span class="user-avatar">{{ user?.name | initials }}</span>
        </div>
    </div>
</div>

<nz-dropdown-menu #userMenu="nzDropdownMenu">
    <ul nz-menu>
        <li nz-menu-item [routerLink]="['/org-settings']">
            {{ 'COMMON.ORG_SETTINGS' | translate }}
        </li>
        <li nz-menu-item [routerLink]="['/my-settings']">
            {{ 'COMMON.MY_SETTINGS' | translate }}
        </li>
        <li *ngIf="'platform.enable-switching-to-pecan-4' | ffObserve | async" nz-menu-item class="toggle">
            {{ 'COMMON.PECAN_4_0_ENABLE' | translate }}
            <nz-switch
                nzSize="small"
                (click)="$event.stopPropagation()"
                [ngModel]="newPecanExperienceEnabled$ | async"
                (ngModelChange)="onToggleChange($event)"
            ></nz-switch>
        </li>
        <!-- <li nz-menu-item id="posthog-feature-preview"> -->
        <!-- Posthog handles clicks on this button -->
        <!-- {{ 'COMMON.FEATURES_PREVIEW' | translate }} -->
        <!-- </li> -->
        <li nz-menu-item (click)="logout()" [appAutomationId]="'logout'">
            {{ 'COMMON.LOGOUT' | translate }}
        </li>
    </ul>
</nz-dropdown-menu>
