import {Injectable} from '@angular/core';
import {Intercom, IntercomSettings} from './intercom-core';
import {AuthService, UserDataInterface} from '../../app/core/authentication/auth.service';
import {map, shareReplay} from 'rxjs/operators';
import {combineLatest, merge, Observable, of} from 'rxjs';
import {CustomerService} from '../customer/customer.service';

const baseIntercomSettings: IntercomSettings = {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'v0my8nrp',
};

@Injectable({
    providedIn: 'root',
})
export class IntercomService {
    private intercomSettings$: Observable<IntercomSettings> = combineLatest([
        merge(of(undefined as string), this.authService.intercomUserHash$),
        merge(of(undefined as UserDataInterface), this.authService.user$),
        merge(of<string>(undefined), this.customerService.selectedTenant$),
    ]).pipe(
        map(([user_hash, user, tenant]) => {
            if (!user_hash || !user || !tenant) {
                return undefined;
            }
            return {
                ...baseIntercomSettings,
                user_hash,
                email: user.email,
                name: user.name,
                company: this.getCompany(user, tenant),
            };
        }),
        shareReplay(1),
    );

    constructor(private authService: AuthService, private customerService: CustomerService) {
        this.init();
    }

    public init(): void {
        this.intercomSettings$.subscribe((settings) => {
            if (settings) {
                this.boot();
                this.update(settings);
            } else {
                this.shutdown();
            }
        });
        this.authService.logout$.subscribe(() => {
            this.shutdown();
        });
    }

    public show(): void {
        Intercom('show');
    }

    public showNewMessage(message: string): void {
        Intercom('showNewMessage', message);
    }

    private getCompany(user: UserDataInterface, tenant: string): IntercomSettings['company'] {
        if (user.email?.endsWith('@pecan.ai')) {
            // We pay for Intercom per number of users, and the same
            // user form several companies is counted as several different users.
            // So for pecan users we only want to see "Pecan" as company
            return {name: 'pecan', id: 'pecan'};
        }
        return {
            name: tenant,
            id: tenant,
        };
    }

    private boot(): void {
        Intercom('boot', baseIntercomSettings);
    }

    private shutdown(): void {
        Intercom('shutdown');
    }

    private update(settings: IntercomSettings): void {
        this.setIntercomSettings(settings);
        Intercom('update');
    }

    private setIntercomSettings(settings: IntercomSettings): void {
        (window as any).intercomSettings = settings;
    }
}
