const defaultIconsLocation = 'assets/svg-icons';

/*
 * In case we need to change icon's color, please remove fill property from SVG
 */
export const SvgIconsList: Record<string, string> = {
    TARGET: `${defaultIconsLocation}/target.svg`,
    SMILE: `${defaultIconsLocation}/smile.svg`,
    FAVORITE_FULL: `${defaultIconsLocation}/favorite-full.svg`,
    FAVORITE_EMPTY: `${defaultIconsLocation}/favorite-empty.svg`,
    CROSSED_EYE: `${defaultIconsLocation}/crossed-eye.svg`,
    EYE: `${defaultIconsLocation}/eye.svg`,
    ARROW: `${defaultIconsLocation}/arrow.svg`,
    SEARCH: `${defaultIconsLocation}/search.svg`,
    FOLDER: `${defaultIconsLocation}/folder.svg`,
    EXPAND: `${defaultIconsLocation}/expand.svg`,
    EXPANDED: `${defaultIconsLocation}/expanded.svg`,
    PLUS: `${defaultIconsLocation}/plus.svg`,
    SORT: `${defaultIconsLocation}/sort.svg`,
    FILTER: `${defaultIconsLocation}/filter.svg`,
    PLAY_CIRCLE: `${defaultIconsLocation}/play_circle.svg`,
    FIELD_DOUBLE: `${defaultIconsLocation}/double.svg`,
    PARQUET: `${defaultIconsLocation}/parquet.svg`,
    SNOW_FLAKE: `${defaultIconsLocation}/snowflake.svg`,
    MSSQL: `${defaultIconsLocation}/mssql.svg`,
    BIGQUERY: `${defaultIconsLocation}/bigquery.svg`,
    DB2: `${defaultIconsLocation}/db2.svg`,
    REDSHIFT: `${defaultIconsLocation}/redshift.svg`,
    CURLY_BRACKETS: `${defaultIconsLocation}/curly-brackets.svg`,
};
