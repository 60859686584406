import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {combineLatest, defer, Observable, of, Subject} from 'rxjs';
import {filter, map, repeatWhen, shareReplay, switchMap} from 'rxjs/operators';
import {AuthService} from '../../app/core/authentication/auth.service';
import {throwBackendErrors} from '../../app/shared/utils/throw-backend-errors';
import {HostContextConsumerService, HostContextProviderService} from '../../host-context';

export interface OrgDetails {
    name: string;
    identifier: string;
    account_type: 'MANAGED' | 'SELF_BUILD' | 'DEMO';
    created_at: string;
    tags?: string[];
    trial_ends_at?: string;
    subscription_cancelled_at?: string;

    [key: string]: any;
}

@Injectable({providedIn: 'root'})
export class CustomerService {
    public selectedTenant$: Observable<string> = defer(() => of(this.hcc.tenant.getSelectedTenant())).pipe(
        repeatWhen(() => this.tenantUpdated$),
    );

    private tenantUpdated$ = new Subject();

    public selectedTenantDetails$: Observable<OrgDetails> = this.selectedTenant$.pipe(
        filter((t) => !!t),
        switchMap(() => this.http.get<OrgDetails>('/_org/api/v1/org/details')),
        shareReplay(1),
    );

    public isTrial$: Observable<boolean> = this.selectedTenantDetails$.pipe(
        map((details) => details.tags?.includes('trial')),
    );

    public customers$: Observable<OrgDetails[]> = this.authService.user$.pipe(
        switchMap((user) => (user ? this.getCustomers() : of([]))),
        shareReplay(1),
    );

    public selectedCustomer$: Observable<OrgDetails | undefined> = combineLatest([
        this.selectedTenant$,
        this.customers$,
    ]).pipe(map(([tenant, customers]) => customers.find((c) => c.identifier === tenant)));

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private hcp: HostContextProviderService,
        private hcc: HostContextConsumerService,
    ) {
        this.authService.defaultTenant$.subscribe((tenant) => this.setSelectedTenantIfNotExist(tenant));
        this.authService.logout$.subscribe(() => this.clearSelection());
    }

    public setSelectedTenantIfNotExist(tenant: string): void {
        const currentTenant = this.hcc.tenant.getSelectedTenant();
        if (!currentTenant) {
            this.setSelectedTenant(tenant);
        }
    }

    public setSelectedTenant(tenant: string | undefined): void {
        this.hcp.tenant.setSelectedTenant(tenant);
        this.tenantUpdated$.next();
    }

    public manuallySetTenant(tenant: string): void {
        this.hcp.tenant.setSelectedTenant(tenant);
        this.navigateHome();
    }

    private navigateHome(): void {
        window.location.href = '/';
    }

    private clearSelection(): void {
        this.setSelectedTenant(undefined);
    }

    private getCustomers(): Observable<OrgDetails[]> {
        return this.http.get<OrgDetails[]>(`api/v1/allowed_orgs`).pipe(map(throwBackendErrors));
    }

    public getCustomerById(id: number): Observable<OrgDetails | undefined> {
        return this.customers$.pipe(map((customers) => customers.find((c) => c.customer_id === id)));
    }
}
