import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InitialsPipe} from './pipes/initials.pipe';
import {SafeUrlPipe} from './pipes/safe-url.pipe';
import {TransformPipe} from './pipes/transform.pipe';

@NgModule({
    declarations: [InitialsPipe, SafeUrlPipe, TransformPipe],
    imports: [CommonModule],
    exports: [InitialsPipe, SafeUrlPipe, TransformPipe],
})
export class PipesModule {}
