<ng-container
    *ngIf="{
        trialEndsDate: trialEndsDate$ | async
    } as data"
>
    <nz-alert
        *ngIf="data.trialEndsDate"
        nzBanner
        nzMessage="Your trial ends on {{ data.trialEndsDate | date }}"
        nzCloseable
    ></nz-alert>
</ng-container>
