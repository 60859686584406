import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';

@Injectable({providedIn: 'root'})
export class Auth0RedirectCallbackGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate(): boolean | UrlTree {
        // route.paramsMap is empty for some reason, need to inspect window.location
        if (window.location.search.includes('error')) {
            const url = new URL(window.location.href);
            this.router.navigate(['/login'], {
                state: {
                    error: url.searchParams.get('error_description').toUpperCase(),
                },
            });
            return false;
        }
        return true;
    }
}
