import {FactoryProvider} from '@angular/core';

/**
 * `ClassOf<T>` matches classes / constructors of `T`
 * https://stackoverflow.com/questions/48731626/new-args-t-syntax
 */
type ClassOf<T> = {new (...args: any): T};

export interface FactoryProviderWithDepsMapProps<Deps extends object, ProvidedValue> {
    provide: ClassOf<ProvidedValue>;
    depsMap: {[K in keyof Deps]: ClassOf<Deps[K]>};
    factory: (deps: Deps) => ProvidedValue;
}

export function getFactoryProviderWithDepsMap<Deps extends object, ProvidedValue>(
    props: FactoryProviderWithDepsMapProps<Deps, ProvidedValue>,
): FactoryProvider {
    const depsEntries: [string, any][] = Object.entries(props.depsMap);
    const depsKeys: string[] = depsEntries.map((e) => e[0]);
    const depsValues: string[] = depsEntries.map((e) => e[1]);
    return {
        provide: props.provide,
        useFactory: (...args: any[]): ProvidedValue => {
            const deps: Deps = {} as Deps;
            for (let index in depsKeys) {
                const key = depsKeys[index];
                deps[key] = args[index];
            }
            return props.factory(deps);
        },
        deps: depsValues,
    };
}
