<ng-container *ngIf="{filteredModels: filteredModels$ | async} as data">
    <ng-container *ngTemplateOutlet="search"></ng-container>

    <nz-table #table [nzData]="data.filteredModels">
        <thead>
            <tr>
                <th>ID</th>
                <th>Status</th>
                <th>Created at</th>
                <th>Created by</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let model of table.data">
                <td>{{ model.id }}</td>
                <td>{{ model.status }}</td>
                <td>{{ model.creationDate }}</td>
            </tr>
        </tbody>
    </nz-table>
</ng-container>

<ng-template #search>
    <nz-input-group class="search-input" [nzPrefix]="suffixIconSearch">
        <input
            class="search-input"
            type="text"
            nz-input
            [placeholder]="'MODELS.MANAGEMENT.SEARCH.PLACEHOLDER' | translate"
            [ngModel]="searchQuery$ | async"
            (ngModelChange)="searchQuery$.next($event)"
        />
    </nz-input-group>
    <ng-template #suffixIconSearch>
        <span class="search-icon" nz-icon nzType="search"></span>
    </ng-template>
</ng-template>
