import {Injectable, OnDestroy} from '@angular/core';
import LogRocket from 'logrocket';
import {Observable, Subject, combineLatest} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {AuthService} from '../../../app/core/authentication/auth.service';
import {CustomerService} from '../../customer/customer.service';
import {AnalyticsEmitterWithContext} from '../common-services/analytics-emitter-with-context.service';

interface PlatformAnalyticsContextualPayload {
    email: string;
    tenant: string;
}

@Injectable()
export class PlatformAnalyticsService
    extends AnalyticsEmitterWithContext<PlatformAnalyticsContextualPayload>
    implements OnDestroy
{
    private trackSubject = new Subject<{key: string; payload: object}>();

    // TODO: remove after analytics users stop using it (and use persons instead)
    public email$ = this.authService.user$.pipe(map((u) => u?.email));

    public tenant$ = this.customerService.selectedTenant$.pipe(filter((t) => !!t));
    public logrocketSessionUrl$ = getLogrocketSessionUrl();
    public track$ = this.trackSubject.asObservable();
    public identify$: Observable<{
        email: string;
        props: {email: string; username: string; sqlUsage: string | undefined};
    }> = combineLatest([this.authService.user$.pipe(filter((u) => !!u)), this.authService.userExtras$]).pipe(
        map(([user, extra]) => ({
            email: user.email,
            props: {
                email: user.email,
                username: user.name,
                sqlUsage: extra?.signup_survey?.sql_usage,
            },
        })),
    );

    private localContextualPayload$: Observable<PlatformAnalyticsContextualPayload> = combineLatest([
        this.tenant$,
        this.email$,
    ]).pipe(map(([tenant, email]) => ({tenant, email})));

    private destroy$ = new Subject();

    constructor(private authService: AuthService, private customerService: CustomerService) {
        super((key, payload) => this.trackSubject.next({key, payload}));
        this.setContextualPayloadStream(this.localContextualPayload$);
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}

function getLogrocketSessionUrl(): Observable<string> {
    return new Observable((observer) => {
        LogRocket.getSessionURL((url) => observer.next(url));
    });
}
