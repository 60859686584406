import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CustomerService} from '../../customer/customer.service';

@Component({
    selector: 'app-banner-view',
    templateUrl: './banner-view.component.html',
    styleUrls: ['./banner-view.component.scss'],
})
export class BannerViewComponent {
    public orgDetails$ = this.customerService.selectedTenantDetails$;
    public trialEndsDate$: Observable<Date | undefined> = this.orgDetails$.pipe(
        map((o) => (o.trial_ends_at ? new Date(o.trial_ends_at) : undefined)),
    );
    constructor(private customerService: CustomerService) {}
}
