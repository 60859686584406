import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {PermissionsModule} from '../../app/core/permissions/permissions.module';
import {CustomerSelectionComponent} from './customer-selection/customer-selection.component';

@NgModule({
    imports: [CommonModule, NzSelectModule, FormsModule, NzTypographyModule, NzToolTipModule, PermissionsModule],
    declarations: [CustomerSelectionComponent],
    exports: [CustomerSelectionComponent],
})
export class CustomerModule {}
