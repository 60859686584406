<header>
    <img src="/assets/images/logo-white-v3.svg" />
</header>
<main>
    <div class="content">
        <span nz-typography class="title">
            {{ errorsMapping[error]?.title || 'LOGIN_PAGE.DEFAULT_ERROR_TITLE' | translate }}
        </span>
        <span nz-typography class="description">
            {{ errorsMapping[error]?.description || 'LOGIN_PAGE.DEFAULT_ERROR_DESCRIPTION' | translate }}
        </span>
    </div>
    <div class="actions">
        <ng-container *ngIf="errorsMapping[error]; else defaultActions">
            <button
                *ngFor="let action of errorsMapping[error].actions"
                nz-button
                [nzType]="action.type"
                (click)="action.clickHandler()"
            >
                {{ action.name }}
            </button>
        </ng-container>
        <ng-template #defaultActions>
            <button nz-button nzType="primary" (click)="closeModal()">
                {{ 'LOGIN_PAGE.LOGIN_AGAIN' | translate }}
            </button>
            <button nz-button nzType="text" (click)="sendEmailToSupport()">
                {{ 'LOGIN_PAGE.CONTACT_US' | translate }}
            </button>
        </ng-template>
    </div>
</main>
