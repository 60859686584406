import {Pipe, PipeTransform} from '@angular/core';
import {FeatureFlagsService} from '../feature-flags.service';
import {Observable} from 'rxjs';

@Pipe({
    name: 'ffObserve',
})
export class FFObservePipe implements PipeTransform {
    constructor(private featureFlagsService: FeatureFlagsService) {}

    public transform(value: string): Observable<boolean> {
        return this.featureFlagsService.observe(value);
    }
}
