import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ObserveDirective} from './observe/observe.directive';
import {VarDirective} from './directives/var.directive';
import {AutomationIdDirective} from './automation-id/automation-id.directive';
import {FrameLoaderDirective} from './frame-loader/frame-loader.directive';
import {ElementHoverThrottledDirective} from './elements/element-hover-throttled.directive';

@NgModule({
    declarations: [
        ObserveDirective,
        VarDirective,
        AutomationIdDirective,
        FrameLoaderDirective,
        ElementHoverThrottledDirective,
    ],
    imports: [CommonModule],
    exports: [
        ObserveDirective,
        VarDirective,
        AutomationIdDirective,
        FrameLoaderDirective,
        ElementHoverThrottledDirective,
    ],
})
export class DirectivesModule {}
