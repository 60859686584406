import {WebserverErrorKey} from '../../../../common/modules/types/webserver-error-key';

export class ErrorResolverUtils {
    public static resolveErrorEvent = (customError: CustomErrorEvent): ErrorNotification => {
        if (isWebserverError(customError)) {
            return {
                key: resolveErrorDisplayText(customError.error_key),
                message: customError.error_msg,
            };
        }

        if (isError(customError)) {
            return {
                key: customError.message,
                message: customError.stack,
            };
        }
        return {
            key: customError?.['statusText'] || 'UNKNOWN',
            message: resolveGeneralErrorMessage(customError) || 'UNKNOWN',
        };
    };
}
const isWebserverError = (error: unknown): error is WebserverError =>
    typeof error === 'object' && 'error_key' in error && 'error_msg' in error;

const isError = (error: unknown): error is Error => typeof error === 'object' && 'message' in error && 'stack' in error;

const resolveGeneralErrorMessage = (error: unknown): string => {
    const errorEvent = error?.['error'];
    return typeof errorEvent === 'object' && 'message' in errorEvent ? errorEvent?.['message'] : errorEvent;
};

const resolveErrorDisplayText = (key: keyof typeof WebserverErrorKey | string): string => {
    if (key in WebserverErrorKey) {
        return `WEBSERVER_ERRORS.ERRORS.${key}`;
    }
    return 'WEBSERVER_ERRORS.DEFAULT_ERROR_DISPLAY_TEXT';
};

export interface CustomErrorEvent {
    key: keyof typeof WebserverErrorKey | string | null;
    message: string;
    params?: any;
    statusText: string;
    status: number;
    error?: any;
}

export interface WebserverError extends CustomErrorEvent {
    error_key: string;
    error_msg: string;
}

export type ErrorNotification = {
    key: string;
    message: string;
};
