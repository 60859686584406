import {Injectable, OnDestroy} from '@angular/core';
import {posthog} from 'posthog-js';
import {merge, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {HostContextConsumerService} from '../../../host-context';
import {PlatformAnalyticsService} from './platform-analytics.service';
import {LoginAnalyticsService} from './login-analytics.service';

@Injectable()
export class PosthogExporterService implements OnDestroy {
    private destroy$ = new Subject();

    constructor(
        private platformAnalyticsService: PlatformAnalyticsService,
        private loginAnalyticsService: LoginAnalyticsService,
        private hcc: HostContextConsumerService,
    ) {}

    public init(): void {
        this.initPosthog();
        const {
            track$: platformTrack$,
            identify$,
            tenant$,
            email$,
            logrocketSessionUrl$,
        } = this.platformAnalyticsService;
        const {track$: loginTrack$} = this.loginAnalyticsService;
        merge(platformTrack$, loginTrack$)
            .pipe(takeUntil(this.destroy$))
            .subscribe((t) => posthog.capture(t.key, t.payload));
        tenant$.pipe(takeUntil(this.destroy$)).subscribe((tenant) => posthog.register({tenant}));
        email$.pipe(takeUntil(this.destroy$)).subscribe((email) => posthog.register({email}));
        logrocketSessionUrl$.pipe(takeUntil(this.destroy$)).subscribe((logrocket_session) => {
            posthog.register({logrocket_session});
        });
        identify$.pipe(takeUntil(this.destroy$)).subscribe(({email, props}) => {
            posthog.identify(email, {email, ...props});
        });
    }

    private initPosthog(): void {
        const config = this.hcc.config.getConfig();
        const orgId = config.posthogOrgId;
        const apiHost = config.posthogAPIHost;
        posthog.init(orgId, {api_host: apiHost, opt_in_site_apps: true});
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
