import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Observable, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {CustomerService, OrgDetails} from '../customer.service';
@Component({
    selector: 'customer-selection',
    templateUrl: './customer-selection.component.html',
    styleUrls: ['./customer-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerSelectionComponent {
    public accountTypes: {[key in OrgDetails['account_type']]: string} = {
        DEMO: 'D',
        MANAGED: 'M',
        SELF_BUILD: 'SB',
    };

    public customers$: Observable<OrgDetails[]> = this.customerService.customers$.pipe(
        map((c) => c.sort((a, b) => a.name.localeCompare(b.name))),
    );

    public customersIndex$: Observable<{[identifier: string]: OrgDetails}> = this.customers$.pipe(
        map((customers) => this.indexCustomersByIdentifier(customers)),
    );

    public selectedCustomer$: Observable<OrgDetails> = combineLatest([
        this.customers$,
        this.customerService.selectedTenant$,
    ]).pipe(map(([options, tenant]) => options.find((customer) => customer?.identifier === tenant)));

    constructor(private customerService: CustomerService) {}

    private indexCustomersByIdentifier(customers: OrgDetails[]): {[identifier: string]: OrgDetails} {
        const res = {};
        for (const customer of customers) {
            res[customer.identifier] = customer;
        }
        return res;
    }
}
