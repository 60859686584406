import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {map} from 'rxjs/operators';
import {AuthService} from '../authentication/auth.service';
import {StorageManagerService} from '../../../modules/storage/storage-manager.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private authService: AuthService,
        private storageManagerService: StorageManagerService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authService.isAuthenticated$().pipe(
            map((isAuthenticated) => {
                if (isAuthenticated) {
                    return true;
                } else {
                    this.storageManagerService.loginRedirectUrl.subject$.next(state.url);
                    return this.router.createUrlTree(['/login']);
                }
            }),
        );
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(route, state);
    }
}
