import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PermissionsService} from './permissions.service';
import {PermissionsPipe} from './permissions.pipe';

@NgModule({
    declarations: [PermissionsPipe],
    providers: [PermissionsService],
    imports: [CommonModule],
    exports: [PermissionsPipe],
})
export class PermissionsModule {}
