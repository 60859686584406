import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {SvgIconsList} from '../svg-icons/icons-list';

@Injectable({
    providedIn: 'root',
})
export class SvgIconsService {
    constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

    initSvgIcons(): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.loadIcons();
            resolve();
        });
    }

    private loadIcons(): void {
        Object.entries(SvgIconsList).forEach(([iconName, path]) => {
            this.iconRegistry.addSvgIcon(iconName, this.sanitizer.bypassSecurityTrustResourceUrl(path));
        });
    }
}
