import {CanActivate, Router, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {StorageManagerService} from '../../../modules/storage/storage-manager.service';

@Injectable()
export class LogoutGuard implements CanActivate {
    constructor(private storageManagerService: StorageManagerService, private router: Router) {}

    canActivate(): UrlTree {
        return this.router.parseUrl(this.storageManagerService.loginRedirectUrl.subject$.value || '/login');
    }
}
