import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {posthog} from 'posthog-js';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-top-navigation-bar-links',
    templateUrl: './top-navigation-bar-links.component.html',
    styleUrls: ['./top-navigation-bar-links.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavigationBarLinksComponent {
    constructor() {}
}
