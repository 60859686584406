import {BehaviorSubject} from 'rxjs';

export class StorageManagerItem<T> {
    public subject$: BehaviorSubject<T | undefined>;

    public constructor(private storage: Storage, private key: string) {
        const initialValue = this.loadFromStorage();
        this.subject$ = new BehaviorSubject(initialValue);
        this.subject$.subscribe((v) => this.store(v));
    }

    public storeWithoutSyncingSubject(value: T): void {
        this.store(value);
    }

    private loadFromStorage(): T | undefined {
        const stored = this.storage.getItem(this.key);
        if (!stored) {
            return undefined;
        }
        try {
            return JSON.parse(stored);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Cannot parse storage value for key: ', this.key);
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }

    private store(value: T | undefined): void {
        if (value === undefined) {
            this.storage.removeItem(this.key);
        } else {
            this.storage.setItem(this.key, JSON.stringify(value));
        }
    }
}
