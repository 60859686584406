import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './auth-interceptor';
import {ErrorInterceptor} from './error-interceptor';
import {ResponseInterceptor} from './response-interceptor';
import {CorrelationIdInterceptor} from './correlation-id-interceptor';
import {AuthHttpInterceptor} from '@auth0/auth0-angular';
import {CustomAuthHttpInterceptor} from './custom-auth-http-interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
    {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: CorrelationIdInterceptor, multi: true},
    AuthHttpInterceptor,
    {provide: HTTP_INTERCEPTORS, useClass: CustomAuthHttpInterceptor, multi: true},
];
