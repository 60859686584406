import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ErrorMessageTemplateService} from '../../../modules/errors/services/global-template.service';
import {
    CUSTOM_CSS_CLASS,
    ERROR_MESSAGE_DURATION,
    NOTIFICATION_POSITION,
    PREVENT_DEFAULT_ERROR_HANDLING,
} from './constants/constants';
import {ErrorNotification, ErrorResolverUtils} from './services/error-resolver.utils';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private notification: NzNotificationService, private templateService: ErrorMessageTemplateService) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let errorShown = false;
        return next.handle(request).pipe(
            catchError((err) => {
                const preventDefault = request.headers.get(PREVENT_DEFAULT_ERROR_HANDLING);
                if (preventDefault) {
                    return throwError(err);
                }

                const errorEvent = ErrorResolverUtils.resolveErrorEvent(err);
                if (
                    err.status === 400 ||
                    err.status === 403 ||
                    err.status === 404 ||
                    (err.status >= 500 && err.status < 600)
                ) {
                    this.emitErrorNotification(errorEvent);
                    errorShown = true;
                }
                return throwError(errorEvent.key || errorEvent.message || err.statusText);
            }),
        );
    }

    private emitErrorNotification(errorEvent?: ErrorNotification): void {
        this.notification.template(this.templateService.template, {
            nzPlacement: NOTIFICATION_POSITION,
            nzDuration: ERROR_MESSAGE_DURATION,
            nzData: errorEvent,
            nzClass: CUSTOM_CSS_CLASS,
        });
    }
}
