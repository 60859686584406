export interface IntercomSettings {
    app_id?: string;
    name?: string;
    email?: string;
    user_id?: string;
    api_base?: string;
    company?: {name?: string; id?: string};
    user_hash?: string;
    [key: string]: any;
}

type IntercomBoot = (key: 'boot', intercomSettings: IntercomSettings) => void;
type IntercomUpdate = (key: 'update') => void;
type IntercomShutdown = (key: 'shutdown') => void;
type IntercomShow = (key: 'show') => void;
type IntercomShowMessage = (key: 'showNewMessage', message: string) => void;

type IntercomType = IntercomBoot & IntercomUpdate & IntercomShutdown & IntercomShow & IntercomShowMessage;

export const Intercom: IntercomType = (...args) => (window as any).Intercom(...args);
