<ng-container
    *ngIf="{
        customers: (customers$ | async),
        customersIndex: (customersIndex$ | async),
        selectedCustomer: selectedCustomer$ | async
    } as data"
>
    <nz-select
        *ngIf="data.customers?.length > 1"
        nzShowSearch
        nzSize="small"
        class="customer-selection"
        [nzDropdownMatchSelectWidth]="false"
        [ngModel]="data.selectedCustomer?.identifier"
        [appAutomationId]="'customer-selection'"
        [nzCustomTemplate]="selectedCustomerTemplate"
        [nzPlacement]="'bottomRight'"
        (ngModelChange)="customerService.manuallySetTenant($event)"
        nzDropdownClassName="automation-id-customer-selection"
    >
        <nz-option
            *ngFor="let customer of data.customers"
            [nzLabel]="customer.name + ' - ' + customer.identifier"
            [nzValue]="customer.identifier"
            nzCustomContent
        >
            <ng-container *ngTemplateOutlet="customerItem; context: {identifier: customer.identifier}"></ng-container>
        </nz-option>
    </nz-select>

    <ng-template #selectedCustomerTemplate let-selected>
        <div class="customer-item" *ngIf="{customer: data.customersIndex?.[selected.nzValue]} as data">
            {{ data.customer?.name ?? selected.nzValue }}
        </div>
    </ng-template>

    <ng-template #customerItem let-identifier="identifier">
        <div class="customer-item" *ngIf="{customer: data.customersIndex?.[identifier]} as data">
            <span
                *ngIf="'customer-selection:show-account-type-in-dropdown' | permissions | async"
                class="account-type"
                [nz-tooltip]="data.customer?.account_type"
                nzTooltipPlacement="left"
            >
                {{ accountTypes[data.customer?.account_type] ?? '?' }}
            </span>
            <span>{{ data.customer?.name }}</span>
            <span nz-typography nzType="secondary">({{ identifier }})</span>
        </div>
    </ng-template>
</ng-container>
