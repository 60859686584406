import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/guards/auth.guard';
import {LogoutGuard} from './core/guards/logout.guard';
import {LoginGuard} from './core/guards/login.guard';
import {Auth0RedirectCallbackGuard} from './core/authentication/auth0-redirect-callback-guard.service';
import {Auth0LoginComponent} from './modules/login/auth0/auth0-login.component';
import {LightModelsListPageComponent} from '../modules/models-management/light-models-list-page/light-models-list-page.component';

const routes: Routes = [
    {
        path: 'dashboards',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('../modules/external-dashboard/external-dashboard.module').then((m) => m.ExternalDashboardModule),
        data: {
            showInBeta: true,
        },
    },
    {
        path: 'login',
        canActivate: [LoginGuard],
        component: Auth0LoginComponent,
        data: {breadcrumb: 'login'},
    },
    {
        path: 'logout',
        canActivate: [LogoutGuard],
        component: Auth0LoginComponent,
    },
    {
        path: 'navigate',
        canActivate: [AuthGuard],
        loadChildren: () => import('../modules/navigator/navigator.module').then((m) => m.NavigatorModule),
    },
    {
        path: 'data',
        loadChildren: () => import('../modules/connection/connection.module').then((m) => m.ConnectionModule),
        canActivate: [AuthGuard],
        data: {
            showInBeta: true,
        },
    },
    {
        path: 'org-settings',
        loadChildren: () => import('../modules/org-settings/org-settings.module').then((m) => m.OrgSettingsModule),
        canActivate: [AuthGuard],
        data: {
            showInBeta: true,
        },
    },
    {
        path: 'my-settings',
        loadChildren: () => import('../modules/my-settings/my-settings.module').then((m) => m.MySettingsModule),
        canActivate: [AuthGuard],
        data: {
            showInBeta: true,
        },
    },
    {
        path: 'models',
        component: LightModelsListPageComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'learn',
        canActivate: [AuthGuard],
        loadChildren: () => import('../modules/learn/learn.module').then((m) => m.LearnModule),
    },
    {
        path: 'new-flow',
        canActivate: [AuthGuard],
        loadChildren: () => import('../modules/new-flow/new-flow.module').then((m) => m.NewFlowRoutingModule),
    },
    {
        path: '',
        canActivate: [Auth0RedirectCallbackGuard],
        loadChildren: () => import('../modules/home/home.module').then((m) => m.HomeModule),
    },
    // otherwise redirect to home - this should be the last route
    {
        path: '**',
        redirectTo: 'login',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
