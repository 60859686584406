import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class AuthOrgService {
    constructor(private httpClient: HttpClient) {}

    resolveOrganization(email: string) {
        return this.httpClient.post(`/users/~/organizations`, {
            email: email,
        });
    }
}
