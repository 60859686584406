import {Location} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {NavigationEnd, Router} from '@angular/router';
import {AuthService} from '../../authentication/auth.service';
import {StorageManagerService} from '../../../../modules/storage/storage-manager.service';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-top-navigation-bar',
    templateUrl: './top-navigation-bar.component.html',
    styleUrls: ['./top-navigation-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavigationBarComponent implements OnInit {
    public hideTopBar = true;
    public user$ = this.authService.user$;
    public userCompanyDisplayName$ = this.authService.defaultTenantDisplayName$;
    public newPecanExperienceEnabled$ = this.storageManagerService.pecanExperience.subject$.pipe(
        map((value) => value == 'new'),
    );

    constructor(
        private authService: AuthService,
        private router: Router,
        private location: Location,
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private storageManagerService: StorageManagerService,
    ) {}

    ngOnInit() {
        this.hideTopBar = this.location.path().includes('/login');
        this.cdr.detectChanges();
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                const {url} = val;
                this.hideTopBar = this.isLoginPageState(url);
                this.cdr.detectChanges();
            }
        });
    }

    logout() {
        this.dialog.closeAll();
        this.authService.logout();
    }

    onToggleChange(toggle: boolean) {
        this.storageManagerService.pecanExperience.subject$.next(toggle ? 'new' : 'legacy');
    }

    private isLoginPageState(url: string): boolean {
        return url.includes('login');
    }
}
