import {ActivatedRouteSnapshot, CanActivate, Params, Router, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {iif, Observable, of} from 'rxjs';
import {AuthService as Auth0Service} from '@auth0/auth0-angular';
import {exhaustMap, map} from 'rxjs/operators';

@Injectable()
export class LoginGuard implements CanActivate {
    constructor(private router: Router, private auth0Service: Auth0Service) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
        return this.auth0Service.isAuthenticated$.pipe(
            exhaustMap((authenticated: boolean) =>
                iif(() => authenticated, this.authenticatedFlow$(), this.unauthenticatedFlow$(route.queryParams)),
            ),
        );
    }

    private authenticatedFlow$(): Observable<UrlTree> {
        return of(this.router.parseUrl('/'));
    }

    private unauthenticatedFlow$(params: Params): Observable<boolean> {
        return of(params).pipe(
            map((params: Params) => {
                if (params.invitation || params.organization) {
                    // Invitation flow
                    this.auth0Service.loginWithRedirect({
                        authorizationParams: {
                            organization: params.organization,
                            login_hint: params.login_hint,
                            invitation: params.invitation,
                        },
                    });
                    return false;
                }
                return true;
            }),
        );
    }
}
