import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class FeatureFlagsModuleAdapter {
    public context$: Observable<FeatureFlagsAdapterContext>;
    public config: FeatureFlagsConfig;
}

export interface FeatureFlagsConfig {
    unleash: {
        url: string;
        clientKey: string;
        appName: string;
    };
}

export interface FeatureFlagsAdapterContext {
    tenant: {
        id: string;
        accountType: string;
    };
    user: {
        email: string;
    };
}
