import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FFObservePipe} from './pipes/ff-observe.pipe';
import {FeatureFlagsService} from './feature-flags.service';
import {FeatureFlagsModuleAdapter} from './feature-flags-module-adapter';
import {
    FactoryProviderWithDepsMapProps,
    getFactoryProviderWithDepsMap,
} from '../factory-provider-with-deps-map/factory-provider-with-deps-map';

@NgModule({
    declarations: [FFObservePipe],
    imports: [CommonModule],
    exports: [FFObservePipe],
})
export class FeatureFlagsModule {
    public static forRoot<T extends object>(
        props: Omit<FactoryProviderWithDepsMapProps<T, FeatureFlagsModuleAdapter>, 'provide'>,
    ): ModuleWithProviders<FeatureFlagsModule> {
        return {
            ngModule: FeatureFlagsModule,
            providers: [
                getFactoryProviderWithDepsMap({provide: FeatureFlagsModuleAdapter, ...props}),
                FeatureFlagsService,
            ],
        };
    }
}
