import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from '../authentication/auth.service';
import {CustomerService} from '../../../modules/customer/customer.service';
import {catchError, switchMap, take} from 'rxjs/operators';
import {combineLatest, throwError} from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService, private customersService: CustomerService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return this.customersService.selectedTenant$.pipe(
            take(1),
            switchMap((tenant) => {
                const headers = tenant ? req.headers.set('x-tenant-id', tenant) : req.headers;
                const authReq = req.clone({headers});
                return next.handle(authReq);
            }),
            catchError((err) => {
                if (err.status === 401) {
                    this.auth.logout();
                }
                return throwError(err);
            }),
        );
    }
}
