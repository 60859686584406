import {ErrorStateMatcher, ShowOnDirtyErrorStateMatcher} from '@angular/material/core';
import {DatePipe} from '@angular/common';
import {AuthService} from './core/authentication/auth.service';
import {APP_INITIALIZER} from '@angular/core';
import {TooltipConfig} from 'ngx-bootstrap/tooltip';
import {AuthOrgService} from './core/services/auth-org.service';
import {LogoutGuard} from './core/guards/logout.guard';
import {LoginGuard} from './core/guards/login.guard';
import {NZ_CONFIG} from 'ng-zorro-antd/core/config';
import {DispatcherService} from '../../platform-common/host-communication/dispatcher.service';
import {ListenerService} from '../../platform-common/host-communication/listener.service';
import {WalkMeApiService} from '../modules/walk-me';

export function getTooltipConfig(): TooltipConfig {
    return Object.assign(new TooltipConfig(), {
        placement: 'right',
        container: 'body',
    });
}

export const AppProviders = [
    AuthService,
    {
        provide: APP_INITIALIZER,
        useFactory: (authService: AuthService) => {
            return () => authService.init();
        },
        deps: [AuthService],
        multi: true,
    },
    {
        provide: ErrorStateMatcher,
        useClass: ShowOnDirtyErrorStateMatcher,
    },
    {
        provide: TooltipConfig,
        useFactory: getTooltipConfig,
    },
    DatePipe,
    AuthOrgService,
    LogoutGuard,
    LoginGuard,
    {provide: NZ_CONFIG, useValue: {select: {nzBackdrop: true}}},
    DispatcherService,
    ListenerService,
    WalkMeApiService,
];
