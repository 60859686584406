import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '@auth0/auth0-angular';
import {NzModalService} from 'ng-zorro-antd/modal';
import {finalize, take, tap} from 'rxjs/operators';
import {AuthOrgService} from '../../../core/services/auth-org.service';
import {AuthOrganizationInterface} from '../../../shared/models/auth-organization.interface';
import {LoginErrorComponent} from '../error/login-error.component';

@Component({
    selector: 'app-auth0-login',
    templateUrl: './auth0-login.component.html',
    styleUrls: ['./auth0-login.component.scss'],
})
export class Auth0LoginComponent implements OnInit {
    public loading = false;
    public form: FormGroup;
    public organizations = [];

    constructor(
        private authService: AuthService,
        private authOrgService: AuthOrgService,
        modalService: NzModalService,
        private router: Router,
    ) {
        const {state} = this.router.getCurrentNavigation().extras;
        if (state?.error) {
            modalService.create({
                nzContent: LoginErrorComponent,
                nzComponentParams: {
                    error: state.error,
                },
                nzCentered: true,
                nzClosable: false,
                nzFooter: null,
                nzWidth: 560,
                nzBodyStyle: {padding: '0', borderRadius: 'inherit', overflow: 'hidden'},
                nzAutofocus: null,
                nzMaskClosable: false,
            });
        }
    }

    ngOnInit() {
        this.form = new FormGroup({
            email: new FormControl(null, [Validators.required, Validators.email]),
        });
    }

    getOrgByEmail() {
        if (!this.form.valid) {
            return;
        }

        this.loading = true;

        if (this.organizations.length) {
            this.auth0Login(this.form.controls.organization.value);
            return;
        }

        this.authOrgService
            .resolveOrganization(this.form.controls.email.value)
            .pipe(
                tap((orgs: AuthOrganizationInterface[]) => {
                    if (orgs.length > 1) {
                        this.organizations = orgs;
                        this.form.addControl('organization', new FormControl(null, [Validators.required]));
                        return;
                    }

                    if (orgs.length === 1) {
                        this.auth0Login(orgs[0].id);
                    } else {
                        // no organization returned, consider error or provide default
                    }
                }),
                finalize(() => (this.loading = false)),
                take(1),
            )
            .subscribe();
    }

    public auth0Login(org: string) {
        this.authService
            .loginWithRedirect({
                authorizationParams: {
                    organization: org,
                    login_hint: this.form.controls.email.value,
                },
            })
            .pipe(take(1))
            .subscribe();
    }
}
