import {Injectable, OnDestroy} from '@angular/core';
import {UnleashClient} from 'unleash-proxy-client';
import {BehaviorSubject, combineLatest, defer, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, publishReplay, refCount, repeatWhen, take, takeUntil} from 'rxjs/operators';
import {IMutableContext} from 'unleash-proxy-client/src';
import {FeatureFlagsAdapterContext, FeatureFlagsModuleAdapter} from './feature-flags-module-adapter';

@Injectable()
export class FeatureFlagsService implements OnDestroy {
    private unleash: UnleashClient;
    private unleashUpdate$ = new Subject<void>();
    private destroy$ = new Subject<void>();
    private contextUpdateInProgress$ = new BehaviorSubject(false);

    private unleashContext$: Observable<IMutableContext> = this.adapter.context$.pipe(
        map((c) => !!c && this.getUnleashContext(c)),
        publishReplay(1),
        refCount(),
    );

    private readyToObserve$: Observable<boolean> = combineLatest([
        this.unleashContext$,
        this.contextUpdateInProgress$,
    ]).pipe(map(([context, inProgress]) => !!context && !inProgress));

    private unleashContextReady$ = this.unleashContext$.pipe(
        takeUntil(this.destroy$),
        map((v) => !!v),
        distinctUntilChanged(),
    );

    constructor(private adapter: FeatureFlagsModuleAdapter) {
        this.unleash = this.getUnleash();
        this.initContextSync();
        this.unleash.on('update', () => this.unleashUpdate$.next());
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public getInstantValue(toggleName: string): boolean {
        return this.unleash.isEnabled(toggleName);
    }

    public observe(toggleName: string): Observable<boolean> {
        const observableFactory = (): Observable<boolean> => this.getSingleValueStream(toggleName);
        return defer(observableFactory).pipe(repeatWhen(() => this.unleashUpdate$));
    }

    private getSingleValueStream(toggleName: string): Observable<boolean> {
        return this.readyToObserve$.pipe(
            filter((ready) => ready),
            take(1),
            map(() => this.getInstantValue(toggleName)),
        );
    }

    private initContextSync(): void {
        this.unleashContextReady$.subscribe((isContextReady) => {
            if (isContextReady) {
                // eslint-disable-next-line no-console
                this.unleash.start().catch((e) => console.warn('Failed starting unleash', e));
            } else {
                this.unleash.stop();
            }
        });
        this.unleashContext$.pipe(takeUntil(this.destroy$)).subscribe((context) => this.updateContext(context));
    }

    private updateContext(context: IMutableContext): void {
        this.contextUpdateInProgress$.next(true);
        this.unleash
            .updateContext(context ?? {})
            .catch((e) => {
                // eslint-disable-next-line no-console
                console.warn('Failed updating unleash context', e);
            })
            .then(() => {
                // In some circumstances updateContext() resolves before the flags are updated.
                // We run a setTimeout to make sure we emit values only when they're ready
                setTimeout(() => {
                    this.unleashUpdate$.next();
                    this.contextUpdateInProgress$.next(false);
                }, 0);
            });
    }

    private getUnleash(): UnleashClient {
        return new UnleashClient({
            url: this.adapter.config.unleash.url,
            clientKey: this.adapter.config.unleash.clientKey,
            appName: this.adapter.config.unleash.appName,
        });
    }

    private getUnleashContext(adapterContext: FeatureFlagsAdapterContext): IMutableContext {
        return {
            properties: {
                email: adapterContext.user.email,
                tenant: adapterContext.tenant?.id,
                accountType: adapterContext.tenant.accountType,
            },
        };
    }
}
