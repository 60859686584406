import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {v4 as uuid} from 'uuid';

@Injectable()
export class CorrelationIdInterceptor implements HttpInterceptor {
    constructor() {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const correlationId = uuid();
        const newHeaders = request.headers.append('correlation_id', correlationId);
        const newRequest = request.clone({headers: newHeaders});
        return next.handle(newRequest);
    }
}
