import {Injectable} from '@angular/core';
import {HostContextRuntimeConfig} from '@pecan/host-context-sdk';

export class Auth0Config {
    audience: string;
    auth0Domain: string;
    nonOrgAwareClientId: string;
    orgAwareClientId: string;
    scopeNamespace: string;
    useRefreshTokens: boolean;
    useRefreshTokensFallback: boolean;
}

@Injectable()
export class Auth0ConfigService extends Auth0Config {}

export async function getAuth0Provider(config: HostContextRuntimeConfig) {
    const res = await fetch(config.auth0ConfigUrl);
    const auth0Config = await res.json();
    return {provide: Auth0ConfigService, useValue: auth0Config};
}
