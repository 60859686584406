import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ModelsManagementService} from '../services/models-management.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Model} from '../../../common/modules/types/model/model';
import {map, switchMap, tap} from 'rxjs/operators';

@Component({
    selector: 'app-light-models-list-page',
    templateUrl: './light-models-list-page.component.html',
    styleUrls: ['./light-models-list-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightModelsListPageComponent {
    public allModels$ = this.modelsManagementService.getAllModels();
    public searchQuery$ = new BehaviorSubject<string>(null);
    public filteredModels$ = this.allModels$.pipe(switchMap((models) => this.filterModelsByQuery(models ?? [])));

    private filterModelsByQuery(models: Model[]): Observable<Model[]> {
        return this.searchQuery$.pipe(
            map((searchQuery) => {
                if (!searchQuery) {
                    return models;
                }
                const lowerCasedQuery = searchQuery.toLowerCase();
                return models.filter(
                    (m) =>
                        m.name.toLowerCase().includes(lowerCasedQuery) ||
                        m.id.toString().toLowerCase().includes(lowerCasedQuery),
                );
            }),
        );
    }

    public constructor(public modelsManagementService: ModelsManagementService) {}
}
