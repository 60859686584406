import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

const INITIAL_TITLE = 'Pecan';

@Injectable({
    providedIn: 'root',
})
export class DocumentTitleService {
    private titleChange$ = new BehaviorSubject<string>(null);
    public title$: Observable<string> = combineLatest([of(INITIAL_TITLE), this.titleChange$.asObservable()]).pipe(
        map(([title, titleChange]): string => {
            if (titleChange) {
                return titleChange;
            }
            return title;
        }),
    );

    public setTitle(title: string) {
        this.titleChange$.next(title);
    }
}
