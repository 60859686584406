import {Injectable} from '@angular/core';
import type {Model} from '../../../common/modules/types/model/model';
import {Observable, OperatorFunction} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ModelType} from 'src/common/modules/types/model/model-type';

@Injectable()
export class ModelsManagementService {
    public getAllModels(): Observable<Model[]> {
        return this.http.get<WebserverTask[]>(`api/tasks/manage/get_tasks`).pipe(
            throwBackendErrors(),
            map((tasks) => tasks.filter((model) => !!model.blueprint_id).map(resolveModel)),
        );
    }
    constructor(private http: HttpClient) {}
}

function resolveModel(task: WebserverTask): Model {
    return {
        archive: task.is_archive,
        creationDate: new Date(task.creation_date * 1000),
        description: task.description,
        favorite: task.is_favorite,
        hidden: task.is_hidden,
        id: task.task_id,
        name: task.task_name,
        status: task.status,
        ownerId: task.owner,
        blueprintId: task.blueprint_id,
        tags: task.tags,
        isQuick: task.is_quick,
        modelType: task.task_type,
    };
}

export interface WebserverTask {
    blueprint_id?: number;
    creation_date: number;
    description: string;
    is_archive: boolean;
    is_favorite: boolean;
    is_hidden: boolean;
    status: string;
    task_id: number;
    task_name: string;
    created_by: string;
    owner: number;
    tags?: string[];
    is_quick: boolean;
    task_type: ModelType;
}

function throwBackendErrors<T>(): OperatorFunction<T, T> | never {
    return map((res: T): T => {
        if ((res as any)?.status === 'error') {
            throw new Error((res as any).error_msg);
        }
        return res;
    });
}
