import {Injectable} from '@angular/core';
import {fromEvent, Observable} from 'rxjs';
import {FromEventTarget} from 'rxjs/internal/observable/fromEvent';
import {map} from 'rxjs/operators';

@Injectable()
export class ListenerService {
    public listen<T>(target: FromEventTarget<CustomEvent>, name: string): Observable<T> {
        return fromEvent<CustomEvent>(target, name).pipe(map((evt: CustomEvent) => evt.detail as T));
    }
}
