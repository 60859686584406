import {Injectable} from '@angular/core';
import {of, Subject} from 'rxjs';
import {AnalyticsEmitterWithContext} from '../common-services/analytics-emitter-with-context.service';

@Injectable()
export class LoginAnalyticsService extends AnalyticsEmitterWithContext<{}> {
    private trackSubject = new Subject<{key: string; payload: object}>();
    public track$ = this.trackSubject.asObservable();

    constructor() {
        super((key, payload) => this.trackSubject.next({key, payload}));
        this.setContextualPayloadStream(of({}));
    }
}
