import {APP_INITIALIZER, NgModule} from '@angular/core';
import {LogRocketExporterService} from './local-services/logrocket-exporter.service';
import {PlatformAnalyticsService} from './local-services/platform-analytics.service';
import {PosthogExporterService} from './local-services/posthog-exporter.service';
import {LoginAnalyticsService} from './local-services/login-analytics.service';

@NgModule({
    providers: [
        PlatformAnalyticsService,
        LoginAnalyticsService,
        PosthogExporterService,
        LogRocketExporterService,
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [PosthogExporterService, LogRocketExporterService],
            useFactory(
                posthogExporterService: PosthogExporterService,
                logRocketExporterService: LogRocketExporterService,
            ) {
                return (): void => {
                    posthogExporterService.init();
                    logRocketExporterService.init();
                };
            },
        },
    ],
})
export class AnalyticsModule {}
