import {Injectable, OnDestroy} from '@angular/core';
import LogRocket from 'logrocket';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PlatformAnalyticsService} from './platform-analytics.service';

// This is temp - for testing logrocket upgrade
const turnOnInDev = sessionStorage.getItem('react-mfe-essentials:logrocket:turn-on-also-in-dev');

@Injectable()
export class LogRocketExporterService implements OnDestroy {
    private destroy$ = new Subject<void>();

    constructor(private platformAnalyticsService: PlatformAnalyticsService) {}

    public init(): void {
        if (!turnOnInDev && !this.isProduction()) {
            return;
        }
        LogRocket.init('zvwuv2/production-9q6ae', {
            mergeIframes: true,
            shouldDebugLog: false,
        });
        const {identify$, tenant$} = this.platformAnalyticsService;
        tenant$.pipe(takeUntil(this.destroy$)).subscribe((tenant) => {
            LogRocket.track('set tenant', {tenant});
        });
        identify$.pipe(takeUntil(this.destroy$)).subscribe(({email, props}) => {
            LogRocket.identify(email, {...props, name: props.username});
        });
    }

    private isProduction(): boolean {
        return window.location.hostname === 'platform.pecan.ai';
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
