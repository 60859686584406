import {Injectable} from '@angular/core';
import {AuthHttpInterceptor} from '@auth0/auth0-angular';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class CustomAuthHttpInterceptor implements HttpInterceptor {
    constructor(private auth0HttpInterceptor: AuthHttpInterceptor) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.auth0HttpInterceptor.intercept(req, next).pipe(
            catchError((err) => {
                if (
                    err.error === 'login_required' ||
                    err.error === 'missing_refresh_token' ||
                    err.error_description === 'TRIAL_PERIOD_EXPIRED' ||
                    err.error_description == 'UNAUTHORIZED'
                ) {
                    window.dispatchEvent(new CustomEvent('login_required'));
                    return EMPTY;
                }
                return throwError(err);
            }),
        );
    }
}
