import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {PermissionKeys, PermissionsService} from './permissions.service';

@Pipe({
    name: 'permissions',
})
export class PermissionsPipe implements PipeTransform {
    constructor(private permissionsService: PermissionsService) {}

    public transform(key: PermissionKeys): Observable<boolean> {
        return this.permissionsService.getIsPermitted(key);
    }
}
