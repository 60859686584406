import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppErrorMessageTemplateComponent} from './components/app-error-message-template/app-error-message-template.component';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {TranslateModule} from '@ngx-translate/core';
import {NzNotificationModule} from 'ng-zorro-antd/notification';
import {SmallScreenMessageComponent} from './components/mobile-message/small-screen-message.component';
import {NzDividerModule} from 'ng-zorro-antd/divider';

@NgModule({
    declarations: [AppErrorMessageTemplateComponent, SmallScreenMessageComponent],
    imports: [
        CommonModule,
        NzIconModule,
        NzTypographyModule,
        NzModalModule,
        TranslateModule,
        NzNotificationModule,
        NzDividerModule,
    ],
    exports: [AppErrorMessageTemplateComponent, SmallScreenMessageComponent],
})
export class ErrorsModule {}
