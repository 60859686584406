import {Component, Input, OnInit} from '@angular/core';
import {NzButtonType} from 'ng-zorro-antd/button';
import {LoginAnalyticsService} from '../../../../modules/analytics/local-services/login-analytics.service';
import {AuthService} from '../../../core/authentication/auth.service';

@Component({
    selector: 'app-login-error',
    templateUrl: './login-error.component.html',
    styleUrls: ['./login-error.component.scss'],
})
export class LoginErrorComponent implements OnInit {
    @Input() error: string;
    errorsMapping: Record<string, ErrorDetails> = {
        TRIAL_PERIOD_EXPIRED: {
            title: "We hope you've been enjoying your free trial in Pecan.",
            description: "Your trial period has ended, but we'd love to keep you on as a customer.",
            actions: [
                {
                    name: 'Set up a meeting',
                    type: 'primary',
                    clickHandler: () => this.setupMeeting(),
                },
                {
                    name: 'See our pricing',
                    type: 'text',
                    clickHandler: () => this.openPricingPage(),
                },
            ],
        },
        PASSWORD_EXPIRED: {
            title: 'Your password has expired',
            description:
                'It`s been 90 days and it’s time to refresh your password for security.' +
                '\n\nCheck your email for the reset link.',
            actions: [
                {
                    name: 'Back to login',
                    type: 'text',
                    clickHandler: () => this.closeModal(),
                },
            ],
        },
    };

    constructor(private loginAnalyticsService: LoginAnalyticsService, private authServce: AuthService) {}

    public ngOnInit(): void {
        if (this.error === 'TRIAL_PERIOD_EXPIRED') {
            this.loginAnalyticsService.track('User entered end of trial screen');
        }
    }

    public sendEmailToSupport(): void {
        window.open('mailto:support@pecan.ai');
    }

    public setupMeeting(): void {
        window.open('https://calendly.com/d/yv7-r33-67r/15-minutes-with-pecan-product-team', '_blank');
    }

    public openPricingPage(): void {
        window.open('https://www.pecan.ai/pricing', '_blank');
    }

    public closeModal(): void {
        this.authServce.logout();
    }
}

interface ErrorDetails {
    title: string;
    description: string;
    actions: {
        name: string;
        type: NzButtonType;
        clickHandler: () => void;
    }[];
}
