import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-small-screen-message',
    templateUrl: './small-screen-message.component.html',
    styleUrls: ['./small-screen-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmallScreenMessageComponent {
    constructor() {}
}
